/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { Props } from '@atlassian/jira-favourite-button-stateless/src/model/types.tsx';
import FavoriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src/view/view.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

const styles = cssMap({
	root: {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: token('space.050'),
		paddingRight: token('space.050'),
		paddingBottom: token('space.050'),
		paddingLeft: token('space.050'),
	},
});

export function FavoriteCellView(props: Props) {
	return isVisualRefreshEnabled() && fg('visual-refresh_drop_2') ? (
		<Box xcss={rootStyles}>
			<FavoriteButtonStateless {...props} />
		</Box>
	) : (
		<div css={styles.root}>
			<FavoriteButtonStateless {...props} />
		</div>
	);
}

FavoriteCellView.defaultProps = {
	isFavorite: undefined,
};

const rootStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	paddingInline: 'space.050',
	paddingBlock: 'space.100',
	alignItems: 'center',
	height: '40px',
	width: '32px',
});
