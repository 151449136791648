import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import FilterSearch from '@atlassian/jira-common-analytics-v2-wrapped-components/src/filter-search.tsx';
import type { Filter } from '@atlassian/jira-common-directory-v2/src/model/shareable-entities/index.tsx';
import type { FilterViewProps } from '@atlassian/jira-directory-base/src/model/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { FilterBar } from '@atlassian/jira-common-directory-v2/src/components/filter-bar/FilterBar.tsx';
import { FilterItem } from '@atlassian/jira-common-directory-v2/src/components/filter-bar/FilterItem.tsx';
import { usePlans } from '../../../controllers/index.tsx';
import messages from './messages.tsx';

const FilterSearchWithAnalytics = withFireUiAnalytics({
	onChange: 'filterSearch',
})(FilterSearch);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ onFilter }: FilterViewProps<Filter>) => {
	const { filter } = usePlans();
	const { formatMessage } = useIntl();
	const handleQueryChange = (value: string) => {
		onFilter({ text: value });
	};

	return fg('blu-6146-responsive-directories') ? (
		<FilterBar>
			<FilterItem>
				<Box xcss={searchInnerStyles}>
					{/* @ts-expect-error - Type '{ increasedWidth: true; placeholderAlwaysVisible: true; isAlwaysExpanded: true; value: string; onChange: (value: string) => void; }' is missing the following properties from type 'Omit<{ value: string; placeholder: string; describedById?: string | undefined; hasAutoFocus: boolean; isCompact: boolean; onChange: (value: string, args_1: UIAnalyticsEvent) => void; ... 6 more ...; key?: Key | ... 1 more ... | undefined; }, keyof WithAnalyticsEventsProps>': placeholder, hasAutoFocus, isCompact */}
					<FilterSearchWithAnalytics
						placeholderAlwaysVisible
						value={filter}
						onChange={handleQueryChange}
						ariaLabelText={formatMessage(messages.searchFieldArialLabel)}
						shouldFitContainerWidth
						increasedWidth
					/>
				</Box>
			</FilterItem>
		</FilterBar>
	) : (
		/* @ts-expect-error - Type '{ increasedWidth: true; placeholderAlwaysVisible: true; isAlwaysExpanded: true; value: string; onChange: (value: string) => void; }' is missing the following properties from type 'Omit<{ value: string; placeholder: string; describedById?: string | undefined; hasAutoFocus: boolean; isCompact: boolean; onChange: (value: string, args_1: UIAnalyticsEvent) => void; ... 6 more ...; key?: Key | ... 1 more ... | undefined; }, keyof WithAnalyticsEventsProps>': placeholder, hasAutoFocus, isCompact */
		<FilterSearchWithAnalytics
			increasedWidth
			placeholderAlwaysVisible
			isAlwaysExpanded
			value={filter}
			onChange={handleQueryChange}
			ariaLabelText={formatMessage(messages.searchFieldArialLabel)}
		/>
	);
};

const searchInnerStyles = xcss({
	minWidth: '328px',
});
